// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
    width: calc(100% - 40px);
    padding-left: 20px;
}

.container {
    width: 100%;
}

.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.event-list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 10px 20px;
    box-sizing: border-box;
}

.create-event-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.create-event {
    width: 200px;
    height: 225px;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".dashboard {\n    width: calc(100% - 40px);\n    padding-left: 20px;\n}\n\n.container {\n    width: 100%;\n}\n\n.top {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.event-list-container {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n    padding: 10px 20px;\n    box-sizing: border-box;\n}\n\n.create-event-container {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n}\n\n.create-event {\n    width: 200px;\n    height: 225px;\n    background-color: white;\n    border: 1px solid black;\n    border-radius: 10px;\n    margin-top: 20px;\n    font-size: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
