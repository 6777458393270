import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {toast} from "react-toastify";
import EventCard from "../component/EventCard";
import "./DashBoard.css"

const DashBoard = () => {
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState('upcoming');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // 이벤트 목록 가져오기
                const response = await axiosInstance.get('/event');

                setEvents(response.data.eventList || []);
            } catch (error) {
                toast.error(error.message);
                console.error(error.message);
            }
        };

        fetchEvents();
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const options = { month: 'long', day: 'numeric', weekday: 'long' };
        return new Intl.DateTimeFormat('ko-KR', options).format(date);
    };

    const filteredEvents = (events || []).filter(eventData => {
        const eventDate = new Date(eventData.event.startDate);
        eventDate.setHours(0, 0, 0, 0);

        const now = new Date();
        now.setHours(0, 0, 0, 0);

        if (filter === 'upcoming') {
            return eventDate >= now;
        }
        else return eventDate < now;
    });

    const handleCreateEventClick = () => {
        navigate("/events/create");
    }

    return (
        <div className="dashboard">
            <h1>대시 보드에서 모든 이벤트를 관리하세요</h1>
            <div className="container">
                <div className="top">
                    <h2>모든 이벤트</h2>
                    <div className="filter">
                        <button onClick={() => setFilter('upcoming')} className={filter === 'upcoming' ? 'active' : ''}>예정된</button>
                        <button onClick={() => setFilter('past')} className={filter === 'past' ? 'active' : ''}>지난</button>
                    </div>
                </div>
                <div className="event-list-container">
                    {(filteredEvents && filteredEvents.length > 0) ? (
                        filteredEvents.map(eventData => {
                            return (
                                <EventCard
                                    key={eventData.event.id}
                                    id={eventData.event.id}
                                    title={eventData.event.title}
                                    startDate={formatDate(eventData.event.startDate)}
                                    location={eventData.event.location}
                                    imageUrl={eventData.event.imageUrl}
                                    creatorId={eventData.event.creatorId}
                                    count={eventData.attendUserIds.length + 1}
                                />
                            );
                        }
                    )) : (
                        <div>
                            <p>다가오는 이벤트가 없습니다</p>
                            <p>다가오는 이벤트가 없습니다. 하나 주최해보세요!</p>
                            <button onClick={handleCreateEventClick}>+ 이벤트 만들기</button>
                        </div>
                    )}
                    {(filter === 'upcoming' && filteredEvents.length > 0) && <div className="create-event-container">
                        <button onClick={handleCreateEventClick} className="create-event">+</button>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default DashBoard;