// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
}

.signup-form {
    display: flex;
    flex-direction: column;
}

.form-group div {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.signup-form input {
    width: 200px;
    box-sizing: border-box;
    margin-right: 0;
}

.signup-form ul {
    list-style-type: none;
    padding: 0;
}

.signup-form li {
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.agree-check {
    margin: 0 5px 10px 5px;
}

.agree-check input[type="checkbox"] {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.input-error {
    border-color: red;
}

.error-message {
    height: 5px;
    color: red;
    font-size: 10px;
    margin: 0;
    width: 200px;
    word-break: break-word;
}

p.error-message {
    height: 15px;
}

.error-message.invalid {
    display: block;
}

.error-message.valid {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/signup/Signup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,eAAe;IACf,SAAS;IACT,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".signup-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: calc(100vh - 60px);\n}\n\n.signup-form {\n    display: flex;\n    flex-direction: column;\n}\n\n.form-group div {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 5px;\n}\n\n.signup-form input {\n    width: 200px;\n    box-sizing: border-box;\n    margin-right: 0;\n}\n\n.signup-form ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.signup-form li {\n    margin: 5px 0;\n    display: flex;\n    align-items: center;\n}\n\n.agree-check {\n    margin: 0 5px 10px 5px;\n}\n\n.agree-check input[type=\"checkbox\"] {\n    width: 12px;\n    height: 12px;\n    margin-right: 5px;\n}\n\n.input-error {\n    border-color: red;\n}\n\n.error-message {\n    height: 5px;\n    color: red;\n    font-size: 10px;\n    margin: 0;\n    width: 200px;\n    word-break: break-word;\n}\n\np.error-message {\n    height: 15px;\n}\n\n.error-message.invalid {\n    display: block;\n}\n\n.error-message.valid {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
