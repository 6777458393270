import React, {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import {storage} from "../firebase";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import axiosInstance from "../axiosInstance";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import './CreateEvent.css'
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'
import moment from "moment/moment";
import 'moment/locale/ko';
import LoginModal from "../component/modal/LoginModal";
import SignupModal from "../component/modal/SignupModal";

moment.locale('ko');

const EventDetail = () => {
    const { isLoggedIn, setIsLoggedIn } = useAuth();

    const [title, setTitle] = useState("");

    const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
    const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(moment(new Date()).format("M월 DD일 (dd)"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("M월 DD일 (dd)"));

    const [isPlaceInputOpen, setIsPlaceInputOpen] = useState(false);
    const [place, setPlace] = useState("");
    const [tempPlace, setTempPlace] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [placeHistory, setPlaceHistory] = useState([]);
    const [placeError, setPlaceError] = useState(false);
    const historyRef = useRef(null);

    const [about, setAbout] = useState("");

    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState("");

    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const savedHistory = localStorage.getItem("placeHistory");
        if (savedHistory) {
            setPlaceHistory(JSON.parse(savedHistory));
        }
    }, []);

    const formatDateToBackend = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}${month}${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleCreateClick = async () => {
        try {
            if (title === "") {
                toast.error("제목은 " + ERROR_MESSAGES.VALIDATION.REQUIRED_FIELD);
                return;
            }

            if (isLoggedIn) {
                const formattedStartDate = formatDateToBackend(selectedStartDate);
                const formattedEndDate = formatDateToBackend(selectedEndDate);

                const createdEvent = {
                    title: title,
                    description: about,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    location: place,
                    imageUrl: imageUrl || null,
                };

                // 이벤트 생성
                const response = await axiosInstance.post('/event', createdEvent);

                if (response.status === 200) {
                    const createdEventId = response.data.eventList[0].id;
                    navigate(`/events/${createdEventId}`);
                } else {
                    toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
                }
            } else {
                setIsLoginOpen(true);
            }
        } catch (error) {
            toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        }
    }

    // 모달창으로 로그인 성공 시 로그인 여부 반영, 이벤트 생성
    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setIsLoginOpen(false);
        handleCreateClick();
    }

    const handleImageUpload = async (file) => {
        const storageRef = ref(storage, `eventImages/${file.name}`);

        await uploadBytes(storageRef, file);

        return await getDownloadURL(storageRef);
    }

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);

            const url = await handleImageUpload(file);
            setImageUrl(url);
            toast.success("이미지가 성공적으로 업데이트되었습니다!");
        }
    }

    const handleToggleStartCalendar = () => {
        setIsStartCalendarOpen(!isStartCalendarOpen);
        setIsEndCalendarOpen(false);
    }

    const handleToggleEndCalendar = () => {
        setIsEndCalendarOpen(!isEndCalendarOpen);
        setIsStartCalendarOpen(false);
    }

    const handleStartDateChange = (selectedDate) => {
        if (selectedDate.setHours(0, 0, 0, 0) < (new Date()).setHours(0, 0, 0, 0)) {
            toast.error("선택한 시간이 허용된 가장 이른 시간보다 앞섭니다.");
        } else {
            setSelectedStartDate(selectedDate);
            setIsStartCalendarOpen(false);
            setStartDate(moment(selectedDate).format("M월 DD일 (dd)"));
            if (selectedDate > selectedEndDate) {
                setSelectedEndDate(selectedDate);
                setEndDate(moment(selectedDate).format("M월 DD일 (dd)"));
            }
        }
    }

    const handleEndDateChange = (selectedDate) => {
        if (selectedDate < selectedStartDate) {
            toast.error("선택한 시간이 허용된 가장 이른 시간보다 앞섭니다.");
        } else {
            setSelectedEndDate(selectedDate);
            setIsEndCalendarOpen(false);
            setEndDate(moment(selectedDate).format("M월 DD일 (dd)"));
        }
    }

    const handleTogglePlace = () => {
        setIsPlaceInputOpen(true);
    }

    const handlePlaceBlur = (event) => {
        if (historyRef.current && historyRef.current.contains(event.target)) {
            return;
        }

        if (tempPlace.length < 2 || tempPlace.length > 100) {
            setPlaceError(true);
        } else {
            setPlaceError(false);
            setPlace(tempPlace);
            setTempPlace("");
            setIsPlaceInputOpen(false);
            setShowHistory(false);

            if (!placeHistory.includes(tempPlace)) {
                const updatedHistory = [...placeHistory, tempPlace];
                setPlaceHistory(updatedHistory);
                localStorage.setItem("placeHistory", JSON.stringify(updatedHistory));
            }
        }
    };

    const handlePlaceFocus = () => {
        setShowHistory(true);
    };

    const handleSelectHistory = (item) => {
        setPlace(item);
        setTempPlace("");
        setShowHistory(false);
        setPlaceError(false);
        setIsPlaceInputOpen(false);
        console.log("history selected")
    };

    return (
        <>
            {isLoginOpen &&
                <LoginModal
                    isOpen={isLoginOpen}
                    setIsOpen={setIsLoginOpen}
                    onLoginSuccess={() => handleLoginSuccess()}
                    openSignup={() => {
                        setIsLoginOpen(false);
                        setIsSignupOpen(true);
                    }}
                />}
            {isSignupOpen &&
                <SignupModal
                    isOpen={isSignupOpen}
                    setIsOpen={setIsSignupOpen}
                    openLogin={() => {
                        setIsSignupOpen(false);
                        setIsLoginOpen(true);
                    }}
                />}
            <div className="event-detail">
                <div className="image-container">
                    {image ? (
                        <div className="image-placeholder">
                            <img src={image} className="event-image"  alt="event-image"/>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="image-input"
                            />
                        </div>
                    ) : (
                        <div className="image-placeholder empty">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="image-input"
                            />
                        </div>
                    )}
                </div>
                <div className="content-container">
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="event-title-input"
                        placeholder="이벤트 이름"
                    />
                    <div className="date-container">
                        <p>이벤트 날짜 추가</p>
                        <div className="calendar-container">
                            <div className="calendar-btn">
                                <p>시작</p>
                                <input
                                    className="calendar-dropdown"
                                    value={startDate}
                                    onClick={handleToggleStartCalendar}
                                    readOnly
                                />
                            </div>
                            <div className="calendar-btn">
                                <p>종료</p>
                                <input
                                    className="calendar-dropdown"
                                    value={endDate}
                                    onClick={handleToggleEndCalendar}
                                    readOnly
                                />
                            </div>
                        </div>
                        {isStartCalendarOpen && <div className="calendar-wrapper">
                            <Calendar
                                onChange={handleStartDateChange}
                                value={selectedStartDate}
                                formatDay={(locale, date) => moment(date).format("DD")}
                            />
                        </div>}
                        {isEndCalendarOpen && <div className="calendar-wrapper">
                            <Calendar
                                onChange={handleEndDateChange}
                                value={selectedEndDate}
                                formatDay={(locale, date) => moment(date).format("DD")}
                            />
                        </div>}
                    </div>
                    <div className="place-container">
                        <div className="place-btn" onClick={handleTogglePlace} style={{ cursor: "pointer"}}>
                            {(!place) ? (
                                <>
                                    <p>이벤트 위치 추가</p>
                                    <p>오프라인 위치 또는 가상 링크</p>
                                </>
                            ) : (<p>{place}</p>)}
                        </div>
                        {isPlaceInputOpen && <input
                            type="text"
                            value={tempPlace}
                            onChange={(e) => setTempPlace(e.target.value)}
                            onBlur={handlePlaceBlur}
                            onFocus={handlePlaceFocus}
                            className={placeError ? "input-error" : ""}
                            placeholder="위치 또는 가상 링크 입력"
                        />}
                        {showHistory && (
                            <ul className="place-history" ref={historyRef}>
                                {placeHistory.map((item, index) => (
                                    <li key={index} onClick={() => handleSelectHistory(item)}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <p className="about-title">설명</p>
                    <textarea
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                        className="event-about-input"
                        placeholder="이벤트는 무엇에 관한 것인가요?"
                    />
                    <div className="button-container">
                        <button onClick={handleCreateClick}>이벤트 만들기</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventDetail;