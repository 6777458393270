import React, { createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { ERROR_MESSAGES } from "../constants/errorMessages";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const accessToken = localStorage.getItem("accessToken");
                const refreshToken = localStorage.getItem("refreshToken");
                const accessTokenExpiresAt = parseInt(localStorage.getItem("accessTokenExpiresAt"), 10);
                const refreshTokenExpiresAt = parseInt(localStorage.getItem("refreshTokenExpiresAt"), 10);
                const username = localStorage.getItem("username");
                const userId = localStorage.getItem("userId");

                if (accessToken && refreshToken &&
                    accessTokenExpiresAt && Date.now() < accessTokenExpiresAt &&
                    refreshTokenExpiresAt && Date.now() < refreshTokenExpiresAt) {
                    const response = await axiosInstance.post('/check', {
                        accessToken,
                        refreshToken
                    });
                    if (response.status === 200) {
                        setIsLoggedIn(true);
                        setUser({ username, userId });
                        localStorage.setItem("accessToken", response.data.accessToken);
                        localStorage.setItem("refreshToken", response.data.refreshToken);
                        localStorage.setItem("accessTokenExpiresAt", Date.now() + response.data.accessTokenExpiresIn * 1000);
                        localStorage.setItem("refreshTokenExpiresAt", Date.now() + response.data.refreshTokenExpiresIn * 1000);
                    } else {
                        setIsLoggedIn(false);
                        setUser(null);
                    }
                } else {
                    setIsLoggedIn(false);
                    setUser(null);
                }
            } catch (error) {
                console.error(error);
                setIsLoggedIn(false);
                setUser(null);
            }
        }

        checkLoginStatus();
    }, []);

    const login = async (credentials) => {
        try {
            const response = await axiosInstance.post('/login', credentials); //로그인

            const { username, userId, accessToken, accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = response.data;

            localStorage.setItem("username", username);
            localStorage.setItem("userId", userId);

            setUser({ username, userId });
            setIsLoggedIn(true);
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("accessTokenExpiresAt", Date.now() + accessTokenExpiresIn * 1000);
            localStorage.setItem("refreshTokenExpiresAt", Date.now() + refreshTokenExpiresIn * 1000);

            console.log("Login success: ", userId);
        } catch (error) {
            if (error.response && error.response.status === 404) { // 로그인 시도한 이메일이 존재하지 않을 때
                throw new Error(ERROR_MESSAGES.AUTH.UNREGISTERED_EMAIL);
            }

            console.error(error.message);
            throw new Error(ERROR_MESSAGES.AUTH.LOGIN_FAILED);
        }
    };

    const logout = async () => {
        try {
            await axiosInstance.post('/sign-out');
            setIsLoggedIn(false);
            setUser(null);
            localStorage.removeItem("username");
            localStorage.removeItem("userId");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessTokenExpiresAt");
            localStorage.removeItem("refreshTokenExpiresAt");
        } catch (error) {
            console.error(error.message);
            throw new Error(ERROR_MESSAGES.AUTH.LOGOUT_FAILED);
        }
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
