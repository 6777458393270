import React from "react";
import "./Footer.css";
import "../style/colors.css";

const Footer = () => {
    return (
        <div className="footer">
            <div className="about">
                <p className="company">(주) 우디고</p>
                <p>대표: 김민수 | 주소: 서울시 관악구 낙성대로 02 | 사업자 등록번호: 222-86-02558</p>
            </div>
            <div className="detail">
                <p>이용약관</p>
                <p>개인정보처리방침</p>
            </div>
        </div>
    );
};

export default Footer;