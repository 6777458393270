import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import "./EventDetail.css";
import Feed from "../component/Feed";
import StatusChangeModal from "../component/modal/StatusChangeModal";
import Calendar from "react-calendar";
import LoginModal from "../component/modal/LoginModal";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../firebase";
import ProfileCard from "../component/ProfileCard";
import SignupModal from "../component/modal/SignupModal";

const EventDetail = () => {
    const { id } = useParams();
    const { isLoggedIn, setIsLoggedIn, user } = useAuth();

    const [event, setEvent] = useState(null);
    const [status, setStatus] = useState("");

    const [isEditMode, setIsEditMode] = useState(false);
    const [title, setTitle] = useState("");
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [place, setPlace] = useState("");
    const [about, setAbout] = useState("");
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [creatorId, setCreatorId] = useState("");

    const [creatorName, setCreatorName] = useState("");
    const [creatorProfileUrl, setCreatorProfileUrl] = useState("");
    const [creatorCompany, setCreatorCompany] = useState("");
    const [creatorLink, setCreatorLink] = useState("");
    const [creatorIntroduction, setCreatorIntroduction] = useState("");

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState("");
    const [isHostModalOpen, setIsHostModalOpen] = useState(false);

    const [showTooltip, setShowTooltip] = useState(false);

    const navigate = useNavigate();

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const options = { month: 'long', day: 'numeric', weekday: 'long' };
        return new Intl.DateTimeFormat('ko-KR', options).format(date);
    };

    const handleCountClick = () => {
        if (isLoggedIn) navigate(`/events/${id}/profileBoard`);
        else setIsLoginModalOpen(true);
    }

    const fetchEvent = async () => {
        try {
            // 이벤트 정보 가져오기
            const response = await axiosInstance.get(`/event/${id}`);

            const data = await response.data.eventList[0];
            setEvent(data);
            setTitle(data.event.title);
            setStartDate(data.event.startDate);
            setEndDate(data.event.endDate);
            setSelectedDate(new Date(data.event.startDate));
            setPlace(data.event.location);
            setAbout(data.event.description);
            setCreatorId(data.event.creatorId);
            if (data.event.imageUrl) setImageUrl(data.event.imageUrl);

            if (data.event.creatorId) {
                fetchCreator(data.event.creatorId);
            }

            // 참여/미참여 확인
            if (isLoggedIn && user && user.userId) {
                if (data.attendUserIds.includes(user.userId)) setStatus("join");
                else if (data.unattendUserIds.includes(user.userId)) setStatus("not join");
                else if (data.event.creatorId === user.userId) setStatus("host");
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        fetchEvent();
    }, [id, user ? user.userId : null]);

    const fetchCreator = async (creatorId) => {
        try {
            const response = await axiosInstance.get(`/user/${creatorId}/profile`);
            const data = response.data;

            setCreatorName(data.nickname);
            setCreatorProfileUrl(data.profileImageUrl);
            setCreatorCompany(data.company);
            setCreatorLink(data.linkUri);
            setCreatorIntroduction(data.introduction);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleJoinClick = () => {
        if (!isLoggedIn) {
            setIsLoginModalOpen(true);
        } else {
            setCurrentAction("join");
            setIsStatusModalOpen(true);
        }
    };

    const handleNotJoinClick = () => {
        if (!isLoggedIn) {
            setIsLoginModalOpen(true);
        } else {
            setCurrentAction("not-join");
            setIsStatusModalOpen(true);
        }
    };

    const handleStatusChange = async () => {
        try {
            let response;
            if (currentAction === "join") {
                response = await axiosInstance.post(`/event/${id}/attend`);
            } else {
                response = await axiosInstance.post(`/event/${id}/unattend`);
            }

            if (response.status === 200) {
                fetchEvent(); // 상태를 업데이트하여 다시 가져옴
            } else {
                toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
            }
        } catch (error) {
            toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsStatusModalOpen(false); // 모달 닫기
        }
    }

    // const handleEditClick = () => {
    //     setIsEditMode(true);
    // }

    // const handleCompleteClick = async () => {
    //     try {
    //         if (title === "") {
    //             toast.error("제목은 " + ERROR_MESSAGES.VALIDATION.REQUIRED_FIELD);
    //         } else {
    //             const updatedEvent = {
    //                 title: title,
    //                 date: selectedDate,
    //                 place: place,
    //                 about: about,
    //                 image: imageUrl || event.imageUrl
    //             };
    //
    //             await axiosInstance.put(`/event/${id}`, updatedEvent);
    //
    //             setIsEditMode(false);
    //             fetchEvent();
    //         }
    //     } catch (error) {
    //         toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
    //         console.error(error);
    //     }
    // }
    //
    // const handleImageUpload = async (file) => {
    //     const storageRef = ref(storage, `eventImages/${file.name}`);
    //
    //     await uploadBytes(storageRef, file);
    //
    //     return await getDownloadURL(storageRef);
    // }
    //
    // const handleImageChange = async (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImage(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //
    //         const url = await handleImageUpload(file);
    //         setImageUrl(url);
    //         toast.success("이미지가 성공적으로 업데이트되었습니다!");
    //     }
    // }

    // const handleToggleCalendar = () => {
    //     setIsCalendarOpen(!isCalendarOpen);
    // }
    //
    // const handleDateChange = (selectedDate) => {
    //     setSelectedDate(selectedDate);
    //     setIsCalendarOpen(false);
    //     setDate(moment(selectedDate).format("YYYY년 MM월 DD일"));
    // }

    const handleShare = async () => {
        try {
            const currentUrl = window.location.href;
            await navigator.clipboard.writeText(currentUrl);
            toast.success("이벤트 링크가 클립보드에 복사되었습니다.");
        } catch (error) {
            console.error("링크 복사 실패: ", error);
            toast.error("링크 복사에 실패했습니다.");
        }
    };

    const handleHostClick = () => {
        setIsHostModalOpen(true);
    };

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setIsLoginModalOpen(false);
    }

    if (!event) return (
        <p>Loading...</p>
    );

    const count = event.attendUserIds.length + 1;
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    startDateObject.setHours(0, 0, 0, 0);
    endDateObject.setHours(0, 0, 0, 0);

    return (
        <>
            {isLoginModalOpen &&
                <LoginModal
                    isOpen={isLoginModalOpen}
                    setIsOpen={setIsLoginModalOpen}
                    onLoginSuccess={() => handleLoginSuccess()}
                    openSignup={() => {
                        setIsLoginModalOpen(false);
                        setIsSignupModalOpen(true);
                    }}
                />}
            {isSignupModalOpen &&
                <SignupModal
                    isOpen={isSignupModalOpen}
                    setIsOpen={setIsSignupModalOpen}
                    openLogin={() => {
                        setIsSignupModalOpen(false);
                        setIsLoginModalOpen(true);
                    }}
                />}
            {isStatusModalOpen && (
                <StatusChangeModal
                    isOpen={isStatusModalOpen}
                    onClose={() => setIsStatusModalOpen(false)}
                    action={currentAction}
                    onConfirm={handleStatusChange}
                />
            )}
            {isHostModalOpen && (
                <div className="host-modal">
                    <div className="modal-overlay" onClick={() => setIsHostModalOpen(false)} />
                    <ProfileCard
                        profileUserId={creatorId}
                        name={creatorName}
                        company={creatorCompany}
                        about={creatorIntroduction}
                        profileImage={creatorProfileUrl}
                        contact={creatorLink}
                        isModal={true}
                    />
                </div>
            )}
            <div className="event-detail">
                {/*{(status === "host") && (*/}
                {/*    <div className="host-modal">*/}
                {/*        {isEditMode ? (*/}
                {/*            <button className="complete" onClick={handleCompleteClick}>완료</button>*/}
                {/*        ) : (*/}
                {/*            <button className="edit" onClick={handleEditClick}>수정</button>*/}
                {/*        )}*/}
                {/*        <button className="manage">관리</button>*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className="image-container">
                    {isEditMode ? (
                        <>
                            {/*{event.image ? (*/}
                            {/*    <div className="image-placeholder">*/}
                            {/*        <img src={event.image} className="event-image" />*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept="image/*"*/}
                            {/*            onChange={handleImageChange}*/}
                            {/*            className="image-input"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*) : (*/}
                            {/*    <div className="image-placeholder empty">*/}
                            {/*        <input*/}
                            {/*            type="file"*/}
                            {/*            accept="image/*"*/}
                            {/*            onChange={handleImageChange}*/}
                            {/*            className="image-input"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </>
                    ) : (
                        (imageUrl) ? (
                            <img src={imageUrl} className="event-image"/>
                        ) : (
                            <div className="empty-image"></div>
                        )
                    )}

                    <div className="status-container">
                        {(status === "") ? (
                            <>
                                <div className="join" onClick={handleJoinClick}>참여</div>
                                <div className="not-join" onClick={handleNotJoinClick}>미참여</div>
                            </>
                        ) : ((status === "join") ? (
                            <>
                                <div className="join" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onClick={handleNotJoinClick}>
                                    참여 중
                                </div>
                                {showTooltip && <span className="tooltip-text">클릭 시 상태 변경</span>}
                            </>
                            ) : ((status === "not join") ? (
                                <>
                                    <div className="not-join" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onClick={handleJoinClick}>
                                        미참여
                                    </div>
                                    {showTooltip && <span className="tooltip-text">클릭 시 상태 변경</span>}
                                </>
                            ) : (
                                <div className="edit">수정</div>
                            ))
                        )}
                    </div>
                    <div className="count-container">
                    <p className="default" onClick={handleCountClick}>총 참여자&nbsp;</p><p>{count}명</p>
                    </div>
                    <button onClick={handleShare}>share</button>
                </div>
                <div className="content-container">
                    {isEditMode ? (
                        <>
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    value={title}*/}
                            {/*    onChange={(e) => setTitle(e.target.value)}*/}
                            {/*    className="event-title-input"*/}
                            {/*/>*/}
                            {/*<div className="calendar-container">*/}
                            {/*    <input*/}
                            {/*        className="calendar-dropdown"*/}
                            {/*        value={date}*/}
                            {/*        onClick={handleToggleCalendar}*/}
                            {/*        readOnly*/}
                            {/*        placeholder="이벤트 날짜"*/}
                            {/*    />*/}
                            {/*    {isCalendarOpen && <div className="calendar-wrapper">*/}
                            {/*        <Calendar*/}
                            {/*            onChange={handleDateChange}*/}
                            {/*            value={selectedDate}*/}
                            {/*            formatDay={(locale, date) => moment(date).format("DD")}*/}
                            {/*        />*/}
                            {/*    </div>}*/}
                            {/*</div>*/}
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    value={place}*/}
                            {/*    onChange={(e) => setPlace(e.target.value)}*/}
                            {/*    className="event-place-input"*/}
                            {/*/>*/}
                            {/*<p className="about-title">설명</p>*/}
                            {/*<textarea*/}
                            {/*    value={about}*/}
                            {/*    onChange={(e) => setAbout(e.target.value)}*/}
                            {/*    className="event-about-input"*/}
                            {/*/>*/}
                        </>
                    ) : (
                        <>
                            <p className="event-title">{title}</p>
                            {(
                                startDateObject.getTime() === endDateObject.getTime()
                            ) ? (
                                <p>{formatDate(startDate)}</p>
                            ) : (
                                <p>{formatDate(startDate)}&nbsp;-&nbsp;{formatDate(endDate)}</p>
                            )}
                            {place ? <p>{place}</p> : <p>추후공지</p>}
                            <p onClick={handleHostClick} style={{cursor: "pointer"}}>{creatorName}님이 주최하였습니다.</p>
                            <p className="about-title">설명</p>
                            <p className="about-container">{about}</p>
                        </>
                    )}
                    <div className="feed-top">
                        <p className="feed-title">소셜 피드</p>
                    </div>
                    <div className="feed-container">
                        {(!isLoggedIn) && <div className="feed-overlay">
                            <p className="caution">로그인 시 확인할 수 있습니다.</p>
                        </div>}
                        <Feed
                            id={id}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventDetail;