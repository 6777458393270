import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import axiosInstance from "../axiosInstance";
import "./Feed.css";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import StatusChangeModal from "./modal/StatusChangeModal";

const Feed = ({ id }) => {
    const { isLoggedIn, user } = useAuth();
    const [feeds, setFeeds] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    const fetchFeeds = async () => {
        try {
            // 피드 불러오기
            const response = await axiosInstance.get(`/event/${id}/feed`);
            const feedList = response.data.feedList;

            const feedsWithUserProfiles = await Promise.all(feedList.map(async (feed) => {
                const userResponse = await axiosInstance.get(`/user/${feed.creatorId}/profile`);
                return {
                    ...feed,
                    user: {
                        name: userResponse.data.nickname,
                        profileImage: userResponse.data.profileImageUrl
                    }
                };
            }));

            setFeeds(feedsWithUserProfiles);
        } catch (error) {
            console.error("Error fetching feeds: ", error);
        }
    };

    const fetchStatus = async () => {
        try {
            console.log("Logged-in user: ", user.userId);

            // 이벤트 정보 가져오기
            const response = await axiosInstance.get(`/event/${id}`);
            const data = await response.data.eventList[0];

            // 참여/미참여 확인
            if (isLoggedIn && user && user.userId) {
                if (data.attendUserIds.includes(user.userId)) return "join";
                else if (data.unattendUserIds.includes(user.userId)) return "not join";
                else if (data.event.creatorId === user.userId) return "host";
            }
            return "";
        } catch (error) {
            console.error(error.message);
            return "";
        }
    };

    const handleStatusChange = async () => {
        try {
            let response;
            response = await axiosInstance.post(`/event/${id}/attend`);

            if (response.status === 200) {
                fetchStatus(); // 상태를 업데이트하여 다시 가져옴
            } else {
                toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
            }
        } catch (error) {
            toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsStatusModalOpen(false); // 모달 닫기
        }
    }

    useEffect(() => {
        fetchFeeds();
    }, [id]);

    const handlePost = async () => {
        const fetchedStatus = await fetchStatus();

        if (fetchedStatus === "join" || fetchedStatus === "host") {
            if (inputValue.trim() === "") return;

            try {
                // 포스트 전송
                const response = await axiosInstance.post(`/event/${id}/feed`, {eventId: id, content: inputValue});

                if (response.status === 200) {
                    await fetchFeeds();
                    setInputValue("");
                } else {
                    console.error("Failed to post feed");
                }
            } catch (error) {
                console.error("Error posting feed: ", error);
            }
        } else { // 미참여 유저
            setIsStatusModalOpen(true);
        }
    };

    return (
        <>
            {isStatusModalOpen && (
                <StatusChangeModal
                    isOpen={isStatusModalOpen}
                    onClose={() => setIsStatusModalOpen(false)}
                    action={"join"}
                    onConfirm={handleStatusChange}
                />
            )}
            <div className="feeds-container">
                <div className="feed">
                    {feeds.map((feed, index) => {
                        const profileImage = feed.user.profileImage;
                        return (
                            <div className="chat" key={index}>
                                {profileImage ? (
                                    <img src={profileImage} className="chat-profile" alt="Profile" />
                                ) : (
                                    <div className="chat-empty-profile" />
                                )}
                                <p className="user-name">{feed.user.name}</p>
                                <p className="chat-content">{feed.content}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="feed-input-container">
                    {user && user.profileImage ? (
                        <img src={user.profileImage} className="chat-profile" alt="Profile" />
                    ) : (
                        <div className="chat-empty-profile" />
                    )}
                    <div className="feed-input">
                        <input
                            className="feed-input-window"
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button onClick={handlePost} style={{ cursor: 'pointer' }}>포스트</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Feed;
