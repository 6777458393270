// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 10px 20px;
}

@media (max-width: 768px) {
    .profile-card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .profile-card-container {
        grid-template-columns: 1fr;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Participants.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".profile-card-container {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n    padding: 10px 20px;\n}\n\n@media (max-width: 768px) {\n    .profile-card-container {\n        grid-template-columns: repeat(2, 1fr);\n    }\n}\n\n@media (max-width: 480px) {\n    .profile-card-container {\n        grid-template-columns: 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
