import {toast, ToastContainer, ToastOptions} from "react-toastify";
import styled from 'styled-components';
import {ReactNode} from "react";
import "./colors.css"

export const StyledToastContainer = styled(ToastContainer)`
    .Toastify__toast {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 40px;
        
        margin: 0;
        padding: 0;
        
        border: 1px solid var(--border_02);
        border-radius: 12px;
    }
    
    .Toastify__toast-body {
        margin: 0;
        padding: 12px 50px;

        color: rgba(255, 255, 255, 1);
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;

        white-space: nowrap;
    }
    
    .Toastify__toast--success {
        padding: 0;
        margin: 0;
        background: var(--brand_primary_02);
    }
    
    .Toastify__toast--error {
        padding: 0;
        margin: 0;
        background: var(--text_color_red);
    }
`;

const defaultToastOption: ToastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    pauseOnHover: false,
    closeButton: false,
    icon: false,
};

export const Toast = {
    success: (message: ReactNode, options: ToastOptions = {}) => {
        toast.success(message, { ...defaultToastOption, ...options })
    },
    error: (message: ReactNode, options: ToastOptions = {}) => {
        toast.error(message, { ...defaultToastOption, ...options })
    },
};