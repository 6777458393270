import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Header from './component/Header';
import Footer from './component/Footer';
import About from './pages/About';
import CreateEvent from './pages/CreateEvent';
import EmailVerification from "./pages/signup/EmailVerification";
import Signup from './pages/signup/Signup';
import Login from './pages/Login';
import ProfileBoard from "./pages/ProfileBoard";
import DashBoard from "./pages/DashBoard";
import EventDetail from "./pages/EventDetail";
import { useAuth } from "./contexts/AuthContext";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {StyledToastContainer} from "./style/StyledToastContainer.tsx";

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <MainContent />
                <StyledToastContainer limit={1} />
            </AuthProvider>
        </Router>
    );
};

const MainContent = () => {
    const { isLoggedIn } = useAuth();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.scrollToProduct) {
            const productSection = document.getElementById('product');
            if (productSection) {
                productSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>
            <Header />
            <main>
                <Routes>
                    <Route path="/" element={isLoggedIn ? <DashBoard /> : <About />} />
                    {/*<Route path="/signup/email" element={<EmailVerification />} />*/}
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="events/:id/profileBoard" element={<ProfileBoard />} />
                    <Route path="/events/:id" element={<EventDetail />} />
                    <Route path="/events/create" element={<CreateEvent />} />
                </Routes>
            </main>
            <Footer />
        </>
    );
};

export default App;