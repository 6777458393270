// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-card {
    padding: 20px 120px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
}

.hosting {
    position: absolute;
    top: 30px;
    right: 130px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container, .share-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-card p {
    text-align: left;
    word-break: break-all;
}

.event-card .title {
    padding-left: 0;
}

.event-image, .empty-image {
    display: block;
    width: 200px;
    height: 225px;
    border-radius: 10px;
    object-fit: cover;
    margin: 0 auto 10px auto;
}

.empty-image {
    background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/component/EventCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,oCAAoC;IACpC,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".event-card {\n    padding: 20px 120px;\n    box-sizing: border-box;\n    width: 100%;\n    position: relative;\n}\n\n.hosting {\n    position: absolute;\n    top: 30px;\n    right: 130px;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 5px 10px;\n    border-radius: 5px;\n    z-index: 10;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.image-container, .share-btn-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.event-card p {\n    text-align: left;\n    word-break: break-all;\n}\n\n.event-card .title {\n    padding-left: 0;\n}\n\n.event-image, .empty-image {\n    display: block;\n    width: 200px;\n    height: 225px;\n    border-radius: 10px;\n    object-fit: cover;\n    margin: 0 auto 10px auto;\n}\n\n.empty-image {\n    background-color: lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
