import React, { useEffect, useState } from "react";
import { storage } from "../../firebase";
import axiosInstance from "../../axiosInstance";
import "./ProfileEditModal.css"
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "../../constants/errorMessages";

const ProfileEditModal = ({ userId, onClose }) => {
    const [profileImage, setProfileImage] = useState("");
    const [imageUrl, setImageUrl] = useState("");

    const [company, setCompany] = useState("");
    const [link, setLink] = useState("");
    const [about, setAbout] = useState("");

    const [companyError, setCompanyError] = useState(false);
    const [aboutError, setAboutError] = useState(false);

    const [isDisable, setIsDisable] = useState(true);

    const handleCompanyBlur = () => {
        if (company === "" || (company.length < 2 && company.length > 50)) {
            setCompanyError(true);
        } else {
            setCompanyError(false);
        }
    }

    const handleAboutBlur = () => {
        if (about === "" || about.length > 100) {
            setAboutError(true);
        } else {
            setAboutError(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/user/${userId}/profile`);

                const data = response.data;
                setProfileImage(data.profileImageUrl || "");
                setCompany(data.company || "");
                setLink(data.linkUri || "");
                setAbout(data.introduction || "");
            } catch (error) {
                setProfileImage("");
                setCompany("");
                setLink("");
                setAbout("");
            }
        }

        fetchData();
    }, [userId]);

    useEffect(() => {
        if (companyError || aboutError || company === "" || about === "") {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [company, about, companyError, aboutError]);

    const handleImageUpload = async (file) => {
        const storageRef = ref(storage, `profileImages/${file.name}`);

        await uploadBytes(storageRef, file);

        return await getDownloadURL(storageRef);
    }

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);

            const url = await handleImageUpload(file);
            setImageUrl(url);
        }
    };

    const handleSaveProfile = async () => {
        try {
            const postData = {
                profileImageUrl: imageUrl,
                company: company,
                linkUri: link,
                introduction: about
            }

            const response = await axiosInstance.post(`/user/${userId}/profile`, postData);

            if (response.status === 200) {
                onClose();
            } else {
                toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            }

            console.log("프로필 저장 성공");
        } catch (error) {
            console.error("프로필 저장 실패: ", error.message);
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="edit-form">
                <div className="edit-profile-image">
                    {profileImage ? (<img
                        src={profileImage}
                        className="profile-image"
                    />) : (
                        <div className="empty-profile-image"/>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}}
                        id="profile-image-input"
                    />
                    <button
                        onClick={() => document.getElementById('profile-image-input').click()}>
                        사진 변경
                    </button>
                </div>
                <div>
                    <label htmlFor="inputCompany">소속</label>
                    <input
                        type="text"
                        id="inputCompany"
                        value={company}
                        onBlur={handleCompanyBlur}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="ex: 우디고 / 예비 창업가"
                        className={companyError ? "input-error" : ""}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="inputLink">링크</label>
                    <input
                        type="text"
                        id="inputLink"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="링크드인, 소셜미디어, 랜딩 페이지 등 링크를 직접 작성해 주세요"
                    />
                </div>
                <div>
                    <label htmlFor="inputAbout">소개</label>
                    <textarea
                        value={about}
                        id="inputAbout"
                        onBlur={handleAboutBlur}
                        onChange={(e) => setAbout(e.target.value)}
                        placeholder="프로필 카드에 보여질 내용을 100자 이내로 작성하세요"
                        className={aboutError ? "input-error" : ""}
                        required
                    />
                </div>
                <button onClick={handleSaveProfile} disabled={isDisable}>작성 완료하기</button>
            </div>
        </div>
    );
}

export default ProfileEditModal;
