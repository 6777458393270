import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "./EventCard.css";
import axiosInstance from "../axiosInstance";
import {toast} from "react-toastify";

const EventCard = ({ id, title, startDate, location, imageUrl, creatorId, count }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const btnRef = useRef(null);

    const [isHost, setIsHost] = useState(false);
    const [creatorName, setCreatorName] = useState("");

    useEffect(() => {
        if (user && user.userId === creatorId) {
            setIsHost(true);
        };

        const fetchCreator = async () => {
            try {
                const response = await axiosInstance.get(`/user/${creatorId}/profile`);
                const data = response.data;

                setCreatorName(data.nickname);
            } catch (error) {
                console.error(error.message);
            }
        };

        fetchCreator();
    }, [user, creatorId]);

    const handleCardClick = (event) => {
        if (btnRef.current && !btnRef.current.contains(event.target)) {
            navigate(`/events/${id}`);
        }
    }

    const handleShare = async () => {
        try {
            const currentUrl = window.location.href;
            await navigator.clipboard.writeText(currentUrl);
            toast.success("이벤트 링크가 클립보드에 복사되었습니다.");
        } catch (error) {
            console.error("링크 복사 실패: ", error);
            toast.error("링크 복사에 실패했습니다.");
        }
    };

    return (
        <div className="event-card" onClick={handleCardClick}>
            <div className="image-container">
                {isHost && <div className="hosting">Hosting</div>}
                {imageUrl ? (
                        <img src={imageUrl} className="event-image" />
                    ) : (
                        <div className="empty-image" />
                    )
                }
            </div>
            <div className="info">
                <div className="title">
                    <h3>{title}</h3>
                </div>
                <div className="date">
                    <p>{startDate}</p>
                </div>
                <div className="location">
                    <p>{location}</p>
                </div>
                <div className="host">
                    <p>{creatorName}</p>
                </div>
                <div className="count">
                    <p>{count}명 참여 중</p>
                </div>
                <div className="share-btn-container">
                    <button className="share" onClick={handleShare} ref={btnRef}>공유</button>
                </div>
            </div>
        </div>
    );
};

export default EventCard;