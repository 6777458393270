import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Participants from "../component/Participants";
import "./ProfileBoard.css";
import axiosInstance from "../axiosInstance";
import ProfileEditModal from "../component/modal/ProfileEditModal";
import {useAuth} from "../contexts/AuthContext";
import StatusChangeModal from "../component/modal/StatusChangeModal";
import {toast} from "react-toastify";
import {ERROR_MESSAGES} from "../constants/errorMessages";

const ProfileBoard = () => {
    const { id } = useParams();
    const { isLoggedIn, user } = useAuth();

    const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
    const [isJoinOpen, setIsJoinOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    const fetchStatus = async () => {
        try {
            console.log("Logged-in user: ", user.userId);
            
            // 이벤트 정보 가져오기
            const response = await axiosInstance.get(`/event/${id}`);
            const data = await response.data.eventList[0];

            // 참여/미참여 확인
            if (isLoggedIn && user && user.userId) {
                if (data.attendUserIds.includes(user.userId)) return "join";
                else if (data.unattendUserIds.includes(user.userId)) return "not join";
                else if (data.event.creatorId === user.userId) return "host";
            }
            return "";
        } catch (error) {
            console.error(error.message);
            return "";
        }
    };

    const fetchProfile = async () => {
        try {
            const response = await axiosInstance.get(`/user/${user.userId}/profile`);

            const {nickname, company, introduction} = response.data;

            if (!nickname || !company || !introduction) {
                setIsProfileEditOpen(true);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleProfileEditClose = () => {
        setIsProfileEditOpen(false);
    };

    const loadStatusAndProfile = async () => {
        const fetchedStatus = await fetchStatus();

        if (fetchedStatus === "join" || fetchedStatus === "host") {
            await fetchProfile();
            setIsJoinOpen(false);
        } else {
            setIsJoinOpen(true);
        }
    };

    useEffect(() => {
        loadStatusAndProfile();
    }, [user]);

    const handleJoinClick = () => {
        setIsStatusModalOpen(true);
        setIsJoinOpen(false);
    };

    const handleStatusChange = async () => {
        try {
            let response;
            response = await axiosInstance.post(`/event/${id}/attend`);

            if (response.status === 200) {
                loadStatusAndProfile(); // 상태를 업데이트하여 다시 가져옴
            } else {
                toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
            }
        } catch (error) {
            toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsStatusModalOpen(false); // 모달 닫기
        }
    }

    return (
        <>
            {isProfileEditOpen && (
                <ProfileEditModal userId={user.userId} onClose={handleProfileEditClose} />
            )}
            {isJoinOpen && (
                <>
                    <div className="modal-overlay"></div>
                    <div className="join-message">
                        <p>커뮤니티 참여가 불가합니다</p>
                        <p>이벤트 참여자만 접근 가능합니다</p>
                        <button onClick={handleJoinClick}>참여로 변경</button>
                    </div>
                </>
            )}
            {isStatusModalOpen && (
                <StatusChangeModal
                    isOpen={isStatusModalOpen}
                    onClose={() => setIsStatusModalOpen(false)}
                    action={"join"}
                    onConfirm={handleStatusChange}
                />
            )}
            <div className="profile-board">
            <h1 className="title">참여자</h1>
                <div className="participants-container">
                    <Participants
                        id={id}
                    />
                </div>
            </div>
        </>
    );
};

export default ProfileBoard;
