const StatusChangeModal = ({ isOpen, onClose, action, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-container">
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <h2>{action === "join" ? "참여하기" : "미참여"}</h2>
                <p>{action === "join" ? "정말로 참여하시겠습니까?" : "정말로 미참여하시겠습니까?"}</p>
                <button onClick={onConfirm}>{action === "join" ? "참여" : "미참여"}</button>
                <button onClick={onClose}>취소</button>
            </div>
        </div>
    );
};

export default StatusChangeModal;