import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";
import './Participants.css';

const Participants = ({id}) => {
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await axiosInstance.get(`/event/${id}`);
                const eventData = await response.data.eventList[0];

                // attendUserIds가 undefined인 경우 빈 배열로 설정
                const attendUserIds = eventData.attendUserIds || [];

                const profiles = await Promise.all(attendUserIds.map(async (userId) => {
                    const profileResponse = await axiosInstance.get(`/user/${userId}/profile`);
                    return {
                        id: userId,
                        nickname: profileResponse.data.nickname,
                        profileImageUrl: profileResponse.data.profileImageUrl,
                        company: profileResponse.data.company,
                        introduction: profileResponse.data.introduction,
                        linkUri: profileResponse.data.linkUri
                    };
                }));

                setParticipants(profiles);
            } catch (error) {
                toast.error(error.message);
            }
        };

        fetchParticipants();
    }, [id]);

    return (
        <div className="profile-card-container">
            {participants.length > 0 && (
                participants.map((participant, index) => {
                    return (
                        <ProfileCard
                            key={index}
                            profileUserId={participant.id}
                            name={participant.nickname}
                            company={participant.company}
                            about={participant.introduction}
                            profileImage={participant.profileImageUrl}
                            contact={participant.linkUri}
                        />
                    );
                })
            )}
        </div>
    );
};

export default Participants;