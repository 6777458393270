import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import './Signup.css';

const Signup = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [passwordError, setPasswordError] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [isChecked, setIsChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const navigate = useNavigate();

    const handleEmailBlur = async () => {
        try {
            const response = await axiosInstance.post('/user/email', {email});

            if (response.status === 200 && !response.data.isExist) {
                setEmailError("");
            } else {
                setEmailError("이미 사용 중인 이메일입니다.");
            }
        } catch (error) {
            console.error("Error checking email: ", error);
        }
    };

    const handleNameBlur = () => {
        const nameRegex = /^[a-zA-Z가-힣\s]{2,20}$/;

        if (!nameRegex.test(name)) {
            setNameError("이름은 한글 또는 영문(띄어쓰기 포함) 2~20자여야 합니다.");
        } else {
            setNameError("");
        }
    };

    const handlePasswordBlur = () => {
        const lengthValid = password.length >= 8 && password.length <= 20;
        const uppercaseValid = /[A-Z]/.test(password);
        const lowercaseValid = /[a-z]/.test(password);
        const numberValid = /[0-9]/.test(password);
        const specialValid = /[!@#$%^&*]/.test(password);

        setPasswordError({
            length: !lengthValid,
            uppercase: !uppercaseValid,
            lowercase: !lowercaseValid,
            number: !numberValid,
            special: !specialValid
        });
    }

    useEffect(() => {
        const handleConfirmPasswordError = () => {
            if (password !== confirmPassword) {
                setConfirmPasswordError("비밀번호가 일치하지 않습니다.");
            } else {
                setConfirmPasswordError("");
            }
        };

        if (confirmPassword !== "") {
            handleConfirmPasswordError();
        }
    }, [password, confirmPassword]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    }

    useEffect(() => {
        const hasPasswordError = Object.values(passwordError).some((value) => value === true);

        if (nameError === "" && emailError === "" && !hasPasswordError && !confirmPasswordError && isChecked
            && name !== "" && email !== "" && password !== "" && confirmPassword !== "") {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [isChecked, nameError, emailError, passwordError, confirmPasswordError, name, email, password, confirmPassword]);

    const handleSignup = async (event) => {
        event.preventDefault();

        const payload = {
            username: email,
            nickname: name,
            password: password
        };

        try {
            // 회원가입
            const response = await axiosInstance.post('/signup', payload);
            if (response.status === 200) {
                console.log("회원가입 성공: " + response.data.username);
                navigate("/login");
            } else {
                toast.error(ERROR_MESSAGES.AUTH.SIGNUP_FAILED);
            }
        } catch (error) {
            toast.error(ERROR_MESSAGES.AUTH.SIGNUP_FAILED);
            console.error("회원가입 실패: ", error.message);
        }
    };

    return (
        <div className="signup-container">
            <form className="signup-form" onSubmit={handleSignup}>
                <div className="form-group">
                    <h>회원가입</h>
                    <div>
                        <label htmlFor="inputEmail">이메일</label>
                        <input
                            type="email"
                            id="inputEmail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={handleEmailBlur}
                            placeholder="아이디로 사용할 이메일을 입력해 주세요"
                            required
                            className={emailError ? "input-error" : ""}
                        />
                        {emailError && <p className="error-message">{emailError}</p> }
                    </div>
                    <div>
                        <label htmlFor="inputUsername">이름</label>
                        <input
                            type="text"
                            id="inputUsername"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={handleNameBlur}
                            placeholder="한글, 영문 2-20자"
                            required
                            className={nameError ? "input-error" : ""}
                        />
                        {nameError && <p className="error-message">{nameError}</p> }
                    </div>
                    <div>
                        <label htmlFor="inputPassword">비밀번호</label>
                        <input
                            type="password"
                            id="inputPassword"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={handlePasswordBlur}
                            placeholder="영문, 숫자, 특수문자가 모두 들어간 8-20자"
                            required
                            className={Object.values(passwordError).includes(true) ? "input-error" : ""}
                        />
                        <input
                            type="password"
                            id="inputConfirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="비밀번호를 한 번 더 입력해 주세요"
                            required
                            style={{marginTop: '10px'}}
                            className={Object.values(passwordError).includes(true) ? "input-error" : ""}
                        />
                        <ul>
                            <li className={`error-message ${passwordError.length ? 'invalid' : 'valid'}`}>
                                🚫최소 8글자 이상 20자 입력하세요
                            </li>
                            <li className={`error-message ${(passwordError.uppercase || passwordError.lowercase) ? 'invalid' : 'valid'}`}>
                                🚫영문 대문자와 소문자를 포함하세요
                            </li>
                            <li className={`error-message ${passwordError.number ? 'invalid' : 'valid'}`}>
                                🚫숫자를 포함하세요
                            </li>
                            <li className={`error-message ${passwordError.special ? 'invalid' : 'valid'}`}>
                                🚫특수문자(! @ # $ % ^ & * 등)를 포함해 주세요
                            </li>
                        </ul>
                        {confirmPasswordError && <p className="error-message">{confirmPasswordError}</p>}
                    </div>
                </div>
                <div className="agree-check">
                    <input
                        type="checkbox"
                        id="agreeCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="agreeCheckbox">[필수] 개인정보 수집 및 이용 동의</label>
                </div>
                <button className="signup-button" type="submit" disabled={isDisabled}>가입 완료하기</button>
            </form>
        </div>
    );
};

export default Signup;