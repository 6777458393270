import React from 'react';
import { useNavigate } from "react-router-dom";
import './About.css';
import { Toast } from "../style/StyledToastContainer.tsx";
import {Confirm} from "../style/components";

const About = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/events/create");
    }

    return (
        <div className="about-page-container">
            <section className="intro">
                <p className="temp-small-font">소규모 네트워킹부터 B2B/B2C 행사까지</p>
                <p className="temp-big-font">성공적인 이벤트 운영을 위한 올인원 플랫폼</p>
                <div className="button-container">
                    <button className="about-button" onClick={handleClick}>+ 이벤트 만들기</button>
                    <button className="about-button">사용 문의</button>
                </div>
            </section>
            <section id="product">
                <p>프로덕트</p>
            </section>
        </div>
    );
};

export default About;