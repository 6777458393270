// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.edit-profile-image {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    justify-content: center;
    margin: 0 auto;
}

.edit-profile-image .profile-image, .empty-profile-image {
    width: 150px;
    height: 150px;
    border-radius: 20%;
    object-fit: cover;
    background-color: white;
    margin: 0 0 20px 0;
}

.edit-profile-image button {
    position: absolute;
    bottom: 20px;
    right: -20px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: gray;
}`, "",{"version":3,"sources":["webpack://./src/component/modal/ProfileEditModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".edit-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.edit-profile-image {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: fit-content;\n    justify-content: center;\n    margin: 0 auto;\n}\n\n.edit-profile-image .profile-image, .empty-profile-image {\n    width: 150px;\n    height: 150px;\n    border-radius: 20%;\n    object-fit: cover;\n    background-color: white;\n    margin: 0 0 20px 0;\n}\n\n.edit-profile-image button {\n    position: absolute;\n    bottom: 20px;\n    right: -20px;\n    padding: 5px 10px;\n    border-radius: 5px;\n    border: none;\n    cursor: pointer;\n    background-color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
