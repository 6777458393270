// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.calendar-container {
    display: flex;
    flex-direction: column;
}

.calendar-btn {
    display: flex;
    flex-direction: row;
}

.calendar-btn p {
    padding: 0 10px;
}

.calendar-dropdown {
    cursor: pointer;
    text-align: center;
}

.calendar-wrapper {
    z-index: 11;
    position: absolute;
    top: 100%;
    left: 0;
}

.content-container .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    box-sizing: border-box;
}

.place-history {
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
    width: 50%;
}

.place-history li {
    padding: 8px;
    cursor: pointer;
}

.place-history li:hover {
    background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreateEvent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;AACX;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".date-container {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.calendar-container {\n    display: flex;\n    flex-direction: column;\n}\n\n.calendar-btn {\n    display: flex;\n    flex-direction: row;\n}\n\n.calendar-btn p {\n    padding: 0 10px;\n}\n\n.calendar-dropdown {\n    cursor: pointer;\n    text-align: center;\n}\n\n.calendar-wrapper {\n    z-index: 11;\n    position: absolute;\n    top: 100%;\n    left: 0;\n}\n\n.content-container .button-container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 40px 0;\n    box-sizing: border-box;\n}\n\n.place-history {\n    position: absolute;\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    border: 1px solid #ccc;\n    background-color: white;\n    z-index: 1000;\n    width: 50%;\n}\n\n.place-history li {\n    padding: 8px;\n    cursor: pointer;\n}\n\n.place-history li:hover {\n    background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
