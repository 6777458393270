// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: calc(100% - 40px);
    height: 108px;
    padding-left: 40px;
    background-color: var(--base_white);
}

.footer p {
    color: var(--text_neutral_01);
    font-weight: 500;
    font-size: 14px;
    line-height: 16.71px;
    margin: 0;
}

.footer .about {
    width: 642px;
    margin-top: 18px;
    margin-bottom: 14px;
}

.footer .about .company {
    font-weight: 600;
    font-size: 15px;
    line-height: 17.9px;
    margin-bottom: 6px;
}

.footer .detail {
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-bottom: 18px;
}`, "",{"version":3,"sources":["webpack://./src/component/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,wBAAwB;IACxB,aAAa;IACb,kBAAkB;IAClB,mCAAmC;AACvC;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".footer {\n    display: flex;\n    flex-direction: column;\n    justify-content: left;\n    width: calc(100% - 40px);\n    height: 108px;\n    padding-left: 40px;\n    background-color: var(--base_white);\n}\n\n.footer p {\n    color: var(--text_neutral_01);\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 16.71px;\n    margin: 0;\n}\n\n.footer .about {\n    width: 642px;\n    margin-top: 18px;\n    margin-bottom: 14px;\n}\n\n.footer .about .company {\n    font-weight: 600;\n    font-size: 15px;\n    line-height: 17.9px;\n    margin-bottom: 6px;\n}\n\n.footer .detail {\n    display: flex;\n    flex-direction: row;\n    gap: 14px;\n    margin-bottom: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
