import React, { useState } from "react";
import './components.css';
import './colors.css';

const PostInputField = ({ profileImage = null, inputValue, onChange, onClick }) => {
    const [styleType, setStyleType] = useState("inactive");

    const onFocus = () => {
        setStyleType("active");
    };

    const onBlur = () => {
        setStyleType("inactive");
    }

    return (
        <div className={`postInputField ${styleType}`}>
            {profileImage !== null ? (
                <img src={profileImage} alt="Profile" />
            ) : (
                <div className="empty-profile" />
            )}
            <input
                type="text"
                value={inputValue}
                placeholder="포스트를 작성해 주세요"
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
            />
            <button onClick={onClick} style={{cursor: 'pointer'}}>등록</button>
        </div>
    )
};

const TextField = ({ type = "text", placeholder, value, onChange, onBlur, isRequired = false, onError }) => {
    const [error, setError] = useState(null);
    const [styleType, setStyleType] = useState("inactive");

    const handleBlur = (e) => {
        if (onBlur)  {
            const validationError = onBlur(e);
            setError(validationError);
            onError(validationError);

            if (validationError === true || validationError !== "") {
                setStyleType("error");
            } else {
                setStyleType("inactive");
            }
        }
    };

    const onFocus = () => {
        setStyleType("active");
    };

    return (
        <div className={`textField ${styleType}`}>
            <input
                type={type}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                required={isRequired}
            />
        </div>
    );
};

const TextFieldSmall = ({ type = "text", placeholder, value, onChange, isRequired = false }) => {
    const [styleType, setStyleType] = useState("");

    const onMouseEnter = () => {
        setStyleType("hover");
    };

    const onMouseLeave = () => {
        setStyleType("");
    }

    return (
        <div className={`textFieldSmall ${styleType}`}>
            <input
                type={type}
                value={value}
                onChange={onChange}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onMouseLeave}
                placeholder={placeholder}
                required={isRequired}
            />
        </div>
    );
};

const Tooltip = ({ message }) => {
    return (
        <div className="tooltip">
            <p>{message}</p>
        </div>
    );
};

const Confirm = ({ message, btnMessage="확인", buttonNum=1, positive=true }) => {
    return (
        <div className="confirm">
            <p>{message}</p>
            {(buttonNum === 1) ? (
                <button className="one-btn hover-btn">{btnMessage}</button>
            ) : (
                <div className="btn-wrapper">
                    <button className={`two-btn hover-btn ${!positive ? 'negative' : ''}`}>{btnMessage}</button>
                    <button className="two-btn cancel hover-btn">취소</button>
                </div>
            )}
        </div>
    );
};

export { PostInputField, TextField, TextFieldSmall, Tooltip, Confirm };